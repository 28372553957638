import "./Projects.css";
import ProjectsData from "./ProjectsData";
import Project1 from "../assets/9.webp";
import Project2 from "../assets/10.webp";
import Project3 from "../assets/11.webp";

function Projects() {
  return (
    <div className="projects">
      <h1>Recent Projects</h1>
      <p>A dedicated team who understand your business’s technology needs</p>
      <div className="projectscard">
        <ProjectsData
          image={Project1}
          heading="Display LED Installation"
          text="Optional regular cabinet and right-angled cabinet.
          Both can be available for splicing of right angles.
          Right-angled solution is possible with single cabinet.
          Flatter without errors in splicing of slits."
        />
        <ProjectsData
          image={Project2}
          heading="Office Network Installation"
          text="For any organization that uses multiple computers or devices, a reliable network is a necessity. We offer network services that will set your business up for success. We will provide the structure you need to increase and maximize productivity. And, we offer the responsive service you require when issues arise."
        />
        <ProjectsData
          image={Project3}
          heading="Software Engineering"
          text="The need for an impactful, user-driven software development solution is often born out of complex business challenges and dynamic customer needs. Whether you're a startup or a Fortune-500 company, we provide custom software development services to bring to life next-gen mobile apps and innovative web solutions."
        />
      </div>
    </div>
  );
}

export default Projects;
