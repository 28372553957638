import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="about-container">
      <h1>Our History</h1>
      <p>
        McsAccess is owned and managed by MyComputerService & Media, a leading
        brand in computer hardware and software solutions provider. McsAccess is
        counted for its expertise in web and e-commerce solutions.
      </p>
      <h1>Our Mission</h1>
      <p>
        Our belief is that every traditional business should feel comfortable in
        the digital world with our help. The modern era can be confusing with
        new technologies and so our USP is providing quality output that caters
        exactly to the client’s need. As a company, we understand the value of
        meritocracy and maintaining an open dialogue conversation. These values
        are transformed into our work ethics which guides our work practice to
        be able to provide the right kind of service.
      </p>
      <h1>Our Vision</h1>
      <p>
        Our vision is to promote the sustainable and economic growth of
        organisations by introducing them to the digital world through our
        partnership approach. We want to be recognised for our excellence in
        terms of our service as well as our employee relations. The end goal is
        to ensure mutual success and forge long lasting relationships.
      </p>
    </div>
  );
}

export default AboutUs;
