import Image1 from "../assets/4.webp";
import Image2 from "../assets/5.webp";
import Image3 from "../assets/4-1.webp";
import Image4 from "../assets/8.webp";
import "./Details.css";
import DetailsData from "./DetailsData";
const Details = () => {
  return (
    <div className="details">
      <h1>HOW WE HELP YOU DO YOU</h1>
      <p>A Leading IT Strategy Consulting Company</p>

      <DetailsData
        cName="first-det"
        heading="Credibility"
        text="Businesses can have specific tech needs, and not just any commercial IT provider can handle them. We can provide general device maintenance, data backups and even emergency repairs. You want to feel confident that you can get tailored support as soon as you need it. Your service is customized based on your specific needs. As a business, this contract takes a wide range of considerations into account. This includes both emergency support and managed IT services. Your agreement can help you feel more confident in your business’s digital future. It can give you peace about your ability to recover quickly in the event of an emergency."
        img1={Image1}
        img2={Image2}
      />
      <DetailsData
        cName="first-det-reverse"
        heading="Tailored support"
        text="IT concerns look a bit different for every business. The quality of service you receive will be far better when you work with a company that is thoroughly familiar with your network and priorities. To that end, we provide a customized approach that takes each client’s specific needs into account. Equipped with a computer service contract, you'll feel as if you have an entire IT department dedicated to your small business."
        img1={Image3}
        img2={Image4}
      />
    </div>
  );
};

export default Details;
