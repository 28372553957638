import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/1.webp";
import Details from "../components/Details";
import Projects from "../components/Projects";
import Footer from "../components/Footer";
function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={AboutImg}
        title="We take care of IT"
        text="So that you can focus on your business"
        buttonText="Contact Us"
        url="/contact"
        btnClass="show"
      />
      <Details />
      <Projects />
      <Footer />
    </>
  );
}

export default Home;
